<template>
  <v-container fluid fill-height>
    <v-layout align-center justify-center>
      <v-flex xs12 sm8 md4>
        <v-card class="elevation-12">
          <v-toolbar color="primary" dark flat>
            <v-toolbar-title class="display-1">Change password</v-toolbar-title>
          </v-toolbar>
          <v-card-text>
            <v-banner
              v-if="changeRequired"
              icon="mdi-key-change"
              icon-color="white"
              color="error"
              class="banner subtitle-1 white--text font-weight-bold"
            >
              Your current password has expired
            </v-banner>
            <div class="spacer"></div>
            <v-form @submit.prevent="changePassword">
              <v-text-field
                autofocus
                v-model="password1"
                prepend-icon="mdi-lock-reset"
                label="New password"
                :type="showPassword1 ? 'text' : 'password'"
                :append-icon="showPassword1 ? 'mdi-eye' : 'mdi-eye-off'"
                @click:append="showPassword1 = !showPassword1"
                @blur="$v.password1.$touch()"
              />
              <div class="red--text text--lighten-1" v-if="$v.password1.$error">
                <div v-if="!$v.password1.required">
                  <v-icon color="red">mdi-alert-circle-outline</v-icon>
                  Password required
                </div>

                <div v-if="!$v.password1.minLength">
                  <v-icon color="red">mdi-alert-circle-outline</v-icon>
                  Password must be at least 8 characters
                </div>

                <div v-if="!$v.password1.hasUpperCase">
                  <v-icon color="red">mdi-alert-circle-outline</v-icon>
                  Must have an uppercase character
                </div>

                <div v-if="!$v.password1.hasLowerCase">
                  <v-icon color="red">mdi-alert-circle-outline</v-icon>
                  Must have a lowercase character
                </div>

                <div v-if="!$v.password1.hasNumber">
                  <v-icon color="red">mdi-alert-circle-outline</v-icon>
                  Must have at least 1 number
                </div>

                <div v-if="!$v.password1.hasSpecialCharacter">
                  <v-icon color="red">mdi-alert-circle-outline</v-icon>
                  Must have at least 1 special character
                </div>
              </div>

              <v-text-field
                v-model="password2"
                :type="showPassword2 ? 'text' : 'password'"
                label="New Password (again)"
                prepend-icon="mdi-lock-reset"
                :append-icon="showPassword2 ? 'mdi-eye' : 'mdi-eye-off'"
                @click:append="showPassword2 = !showPassword2"
                @blur="$v.password2.$touch()"
              />

              <div
                class="red--text text--lighten-1"
                v-if="$v.password2.$error && !$v.password2.required"
              >
                <v-icon color="red">mdi-alert-circle-outline</v-icon>
                Passwords must match
              </div>

              <v-btn
                type="submit"
                color="success"
                name="button"
                :disabled="$v.$invalid"
              >
                Submit
              </v-btn>

              <div v-if="error" class="red--text text--lighten-1 mt-4">
                <v-icon color="red">mdi-alert-circle-outline</v-icon>
                {{ getErrorMsg() }}
              </div>
            </v-form>

            <div class="spacer"></div>

            <div>
              <ul>
                <li>Must contain at least 8 characters</li>
                <li>Must contain both upper and lower case characters</li>
                <li>Must contain at least 1 number</li>
                <li>
                  Must contain at least special character {{ getSpecalChars }}
                </li>
              </ul>
            </div>
          </v-card-text>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import { required, sameAs, minLength } from "vuelidate/lib/validators";
import {
  hasUpperCase,
  hasLowerCase,
  hasNumber,
  hasSpecialCharacter,
  specialChars,
} from "@/lib/validators/password_validators";

export default {
  name: "ChangePassword",

  data() {
    return {
      password1: "",
      password2: "",
      error: null,
      showPassword1: false,
      showPassword2: false,
    };
  },

  validations: {
    password1: {
      required,
      minLength: minLength(8),
      hasUpperCase,
      hasLowerCase,
      hasNumber,
      hasSpecialCharacter,
    },
    password2: {
      required,
      sameAsPassword: sameAs("password1"),
    },
  },

  computed: {
    changeRequired() {
      return this.$store.getters["user/passwordChangeRequired"];
    },

    getSpecialChars() {
      return specialChars;
    },
  },

  methods: {
    changePassword() {
      this.error = null;
      this.$store
        .dispatch("user/changePassword", {
          password: this.password1,
          config: { ignoreError: true },
        })
        .then(() => {
          //console.log("in .then of changePassword");
          this.$router.push({ name: "login" });
        })
        .catch((err) => {
          this.error = err.response;
        });
    },

    getErrorMsg() {
      //console.log("this.error: ", this.error);

      if (this.error && this.error.data) {
        return this.error.data;
      } else {
        return `${this.error.status}: ${this.error.statusText}`;
      }
    },
  },
};
</script>

<style scoped>
.spacer {
  height: 25px;
}
.banner {
  color: red;
}
</style>
